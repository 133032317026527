import clsx from 'clsx'
import Button from '../Button'
import CopyRedesign from '../Icons/CopyRedesign'
import { copyValue } from '../utils/copyValue'

import styles from './ControlledCopyButton.module.scss'

type Props = {
  textToCopy?: string | object
  isCopied?: boolean
  onCopied?: () => void
  analyticsID?: string
}

const ControlledCopyButton = ({ textToCopy = '', isCopied, onCopied, analyticsID = '' }: Props) => (
  <Button
    type="button"
    className={clsx(styles.copyButton, { [styles.copied]: isCopied })}
    onClick={async () => {
      await copyValue(textToCopy)
      onCopied?.()
    }}
    analyticsID={analyticsID}
  >
    <CopyRedesign fill={isCopied ? '#FFFFFF' : '#1B244D'} />
  </Button>
)

export default ControlledCopyButton
