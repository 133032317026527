/**
 * Only includes the values that exist in Salesforce.
 */
export const statusValues = [
  'Open',
  'Open-Hold',
  'Closed-Not Purchased',
  'Closed-Purchased',
  'Closed-No Activity',
  'Closed-No Contact Info Provided',
  'Closed Lost',
  'Closed Won',
  'Closed Written',
  'Closed Delivered',
  'Closed-Opt Out'
] as const

export const openStatusValues = statusValues.filter((x) => x.includes('Open'))
export const closedStatusValues = statusValues.filter((x) => x.includes('Closed'))

export type StatusType = typeof statusValues[number]

// The values that the frontend can display
export const mappedStatusValues = [
  'Open',
  'Open-Hold',
  'Closed-Not Purchased',
  'Closed-Purchased',
  'Closed-No Activity',
  'Closed-No Contact Info Provided'
] as const

export const clientelingMappedStatusValues = [
  'Open',
  'Open-Hold',
  'Closed-Not Purchased',
  'Closed-Purchased',
  'Closed-Opt Out',
  'Closed-No Activity',
  'Closed-No Contact Info Provided'
] as const

// temp values to not fail procedures
export const communicationMethods = ['Call', 'Text', 'Email', 'Cell', 'Business', 'Home'] as const

// Web and Mattress Matcher are not values for user selection and will display on Lead Details when a lead was systematically created
export const sourceLeadMethods = [
  'Social Media',
  'Referral',
  'Phone',
  'Podium',
  'Events & Expositions',
  'Walk-in',
  'Other',
  'National Sales Referral',
  'Mattress Matcher',
  'Web'
] as const

export const stages = [
  'Digital Business Card',
  'Visit Recap',
  'First Check In',
  'Reconnect',
  'Pre-Delivery',
  '7 Day PP',
  '30 Day PP',
  '90 Day PP',
  'Completed',
  'Not Started'
] as const

export const stageIdMap: Record<Exclude<typeof stages[number], 'Not Started' | 'Completed'>, number> = {
  'Digital Business Card': 1,
  'Visit Recap': 2,
  'First Check In': 3,
  Reconnect: 4,
  'Pre-Delivery': 5,
  '7 Day PP': 6,
  '30 Day PP': 7,
  '90 Day PP': 8
}

export const stageStatuses = ['Open', 'Completed', 'Opt-Out'] as const

export const MM_DOWN_MESSAGE = 'MM API appears to be down.'
export const NEO4J_DOWN_ERROR = 'Neo4j Server Unavailable'

export type ProductType = 'Mattresses' | 'Foundations & Bases' | 'Bedding' | 'Pillows' | 'Furniture'

// Define the mapping using the ProductType
export const PRODUCT_TYPES: Record<number, ProductType> = {
  5637147600: 'Mattresses',
  5637147587: 'Foundations & Bases',
  5637147593: 'Bedding',
  5637147606: 'Pillows',
  5637147577: 'Furniture'
}
