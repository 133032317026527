type Props = {
  className?: string
  width?: string | number
  height?: string | number
  fillColor?: string
}

const ExternalLinkIcon = ({ className, width = '24', height = '24', fillColor = '#2D2926' }: Props) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19 19H5V5H12V3H5C3.89 3 3 3.9 3 5V19C3 20.1 3.89 21 5 21H19C20.1 21 21 20.1 21 19V12H19V19ZM14 3V5H17.59L7.76 14.83L9.17 16.24L19 6.41V10H21V3H14Z"
      fill={fillColor}
    />
  </svg>
)

export default ExternalLinkIcon
