import { FunctionComponent } from 'react'
import clsx from 'clsx'
import IHorizontalBarProps from './interfaces/horizontalBar'
import UserSection from './UserSection'
import useCustomer from '../SessionExpireWrapper/hooks/useCustomer'
import styles from './horizontalBar.module.scss'

const HorizontalBarRedesign: FunctionComponent<IHorizontalBarProps> = ({
  bodyContent,
  adjustForVerticalBar = false,
  fixed = false,
  wrapperClass,
  handleAddProfileClick,
  handleProfileClick,
  handleNewSessionClick,
  profileLinkProps = { btnType: 'link' },
  addProfileButtonProps = { label: 'Save Profile', size: 'sm' },
  idPrefix = '',
  isRedesign = false,
  ...rest
}) => {
  const horizontalClasses = clsx(
    styles.horizontal,
    fixed && styles.fixed,
    adjustForVerticalBar && styles.adjustForVerticalBar,
    isRedesign && styles.redesign,
    wrapperClass
  )
  const [storageProfileDetail] = useCustomer()

  if (typeof bodyContent !== 'undefined') {
    return (
      <header className={styles.horizontal} data-testid="horizontal-bar" {...rest}>
        {bodyContent}
      </header>
    )
  }

  return (
    <header className={horizontalClasses} data-testid="horizontal-bar" {...rest}>
      <div className={clsx(styles.settingsSection, isRedesign && styles.redesign)}>
        <UserSection
          idPrefix={idPrefix}
          profileDetails={storageProfileDetail}
          handleAddProfileClick={handleAddProfileClick}
          handleProfileClick={handleProfileClick}
          handleNewSessionClick={handleNewSessionClick}
          profileLinkProps={profileLinkProps}
          addProfileButtonProps={addProfileButtonProps}
          isRedesign={isRedesign}
        />
      </div>
    </header>
  )
}

export default HorizontalBarRedesign
