import { IUnifySleepExpertDetail, MFRM_UNIFY_SE_DETAIL_KEY } from '../../conventions'
import IAvatarDetail from '../interfaces/avatar'
import { mockAvatarDetail } from '../mocks'
import { getStorageItem } from './useStorage'

const getInitials = (fullName: string, showLastName?: boolean) => {
  const allNames = fullName.trim().split(' ')

  if (!showLastName) {
    return fullName.trim()[0]
  }

  const initials = allNames.reduce((acc, curr, index) => {
    if (index === 0 || index === allNames.length - 1) {
      acc = `${acc}${curr.charAt(0).toUpperCase()}`
    }
    return acc
  }, '')

  return initials
}

const useAvatarData = (
  name: string,
  title: string,
  storeNumber: string,
  showLastName: boolean,
  storageKey: string = MFRM_UNIFY_SE_DETAIL_KEY
) => {
  const storageDetail = getStorageItem<IAvatarDetail | IUnifySleepExpertDetail>({
    key: storageKey,
    value: mockAvatarDetail
  })

  const avatarName = name || storageDetail?.name || mockAvatarDetail.name

  const displayName = showLastName ? avatarName : avatarName.trim().split(' ')[0]

  const storeNumberFormatted = storeNumber ? `#${storeNumber}` : undefined

  const avatarTitle =
    storeNumberFormatted ||
    title ||
    (storageDetail as IUnifySleepExpertDetail)?.storeNumber ||
    (storageDetail as IAvatarDetail)?.title

  const initials = getInitials(avatarName, showLastName)

  return { displayName, avatarTitle, initials }
}

export default useAvatarData
