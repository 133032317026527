export const copyValue = async (value: string | object) => {
  if (!navigator && !document) return

  const valueToCopy = typeof value === 'object' ? JSON.stringify(value, null, 2) : value
  const { clipboard } = navigator

  if (clipboard) {
    try {
      await clipboard.writeText(valueToCopy)
    } catch (error) {
      console.error(error)
    }
  } else {
    document.execCommand('copy', true, valueToCopy)
  }
}
