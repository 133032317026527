import React, { ChangeEvent, FunctionComponent } from 'react'
import clsx from 'clsx'
import Button from '../Button'
import NewSessionIcon from '../Icons/NewSessionIcon'
import PersonCircle from '../Icons/PersonCircle'
import IUserSectionProps from './interfaces/userSection'
import initNewSessionHandler from './utils/initNewSessionHandler'
import PersonAddIcon from '../Icons/PersonAddIcon'
import styles from './horizontalBar.module.scss'

const UserSection: FunctionComponent<IUserSectionProps> = ({
  profileDetails,
  idPrefix = '',
  handleAddProfileClick,
  handleProfileClick,
  handleNewSessionClick,
  profileLinkProps,
  addProfileButtonProps,
  isRedesign
}) => {
  const { firstName = '', lastName = '', Id } = profileDetails || {}

  return (
    <>
      <a
        href="javascript: undefined"
        onClick={(e: React.MouseEvent<HTMLAnchorElement>) => initNewSessionHandler(e, handleNewSessionClick)}
        className={clsx(styles.userSection, styles.newSessionButton, isRedesign && styles.redesign)}
        data-testid="new-session-button"
        id={`${idPrefix}header_new_button`}
      >
        {isRedesign ? (
          <>
            <PersonAddIcon />
            <div className={styles.newSessionRedesign} />
          </>
        ) : (
          <>
            <div className={styles.newSession} />
            <NewSessionIcon fillColor="#1b244d" />
          </>
        )}
      </a>
      {Id ? (
        <Button
          className={styles.userSection}
          href="javascript: undefined"
          id={`${idPrefix}header_profile_button`}
          onClick={(e: ChangeEvent<HTMLInputElement>) => {
            const onComplete = () => {
              window.location.href = '/profile'
            }
            if (handleProfileClick) {
              handleProfileClick(e, onComplete)
            } else {
              onComplete()
            }
          }}
          {...profileLinkProps}
        >
          <div className={styles.name}>{`${firstName} ${lastName}`.trim()}</div>
          {!isRedesign && <PersonCircle />}
        </Button>
      ) : (
        <Button
          className={clsx(styles.addCustomerButton, isRedesign && styles.redesign)}
          onClick={handleAddProfileClick}
          {...addProfileButtonProps}
          id={`${idPrefix}header_save_profile_button`}
        >
          {isRedesign ? (
            <div className={styles.addCustomerText}>{addProfileButtonProps?.label}</div>
          ) : (
            <>
              <div className={clsx(styles.addCustomerTextLandSacape, styles.addCustomerText)}>
                {addProfileButtonProps?.label}
              </div>
              <div className={clsx(styles.addCustomerTextPortrait, styles.addCustomerText)}>
                {addProfileButtonProps?.label.split(' ')[0]}
              </div>
              <PersonCircle />
            </>
          )}
        </Button>
      )}
    </>
  )
}

export default UserSection
