import { useContext } from 'react'
import { ProductSearchPopupCtx } from '../context'
import { ProductSearchResultItem } from '../schema'
import ProductSearchListItem from './ProductSearchListItem'
import Typography from '../../Typography'
import type IProductSearchList from './interfaces/productSearchList'

import styles from './productSearchList.module.scss'

const ProductSearchList = ({ analyticsPrefixId }: IProductSearchList) => {
  const { searchTerm, isLoading, searchResults } = useContext(ProductSearchPopupCtx)

  const {
    productSearchList,
    productSearchinfoMsg,
    productSearchListTitle,
    productSearchNoResultMsg,
    productSearchListContainer
  } = styles

  const searchInputNotEmpty = searchTerm?.length > 0
  const hasSearchResult = searchResults?.length > 0

  const isSearched = (hasSearchResult || isLoading) && searchInputNotEmpty

  if (!searchInputNotEmpty) return null

  return (
    <div className={productSearchListContainer}>
      <Typography className={productSearchListTitle} variant="paragraph-sm">
        Products
      </Typography>
      <div className={productSearchList}>
        {isSearched ? (
          <>
            {searchResults.map((searchResultItem: ProductSearchResultItem, index: number) => (
              <ProductSearchListItem
                analyticsPrefixId={`${analyticsPrefixId}_result_pos${index}`}
                key={searchResultItem.productSku}
                productIndex={index}
                {...searchResultItem}
              />
            ))}
            {(!isLoading || hasSearchResult) && (
              <p
                data-testid="refine-search-helper-text"
                className={`${productSearchNoResultMsg} ${productSearchinfoMsg}`}
              >
                Not seeing a product? Please refine your search terms.
              </p>
            )}
          </>
        ) : (
          <p data-testid="no-result-helper-text" className={productSearchNoResultMsg}>
            No results for “{searchTerm}”. Please refine your search terms.
          </p>
        )}
      </div>
    </div>
  )
}

export default ProductSearchList
