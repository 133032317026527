import React, { FunctionComponent } from 'react'
import clsx from 'clsx'
import { MFRM_UNIFY_SE_DETAIL_KEY } from '../conventions'
import IAvatarDetail, { IAvatarProps, IUnifySleepExpertDetail } from './interfaces/avatar'
import useAvatarData from './hooks/useAvatarData'

import styles from './styles/avatar.module.scss'

interface Props extends IAvatarDetail, IUnifySleepExpertDetail, IAvatarProps {}

const Avatar: FunctionComponent<Props> = ({
  name = '',
  title = '',
  storeNumber = '',
  showLastName = false,
  storageKey = MFRM_UNIFY_SE_DETAIL_KEY,
  onClick
}) => {
  const { displayName, avatarTitle, initials } = useAvatarData(name, title, storeNumber, showLastName, storageKey)

  return (
    <div className={clsx(styles.container, { [styles.clickable]: !!onClick })} onClick={onClick}>
      <span className={styles.circle}>{initials}</span>
      <span title={displayName} className={styles.name}>
        {displayName}
      </span>
      <span title={avatarTitle} className={styles.title}>
        {avatarTitle}
      </span>
    </div>
  )
}

export default Avatar
