import clsx from 'clsx'
import React, { FunctionComponent, MouseEvent } from 'react'
import INavOption from './interfaces/navOption'
import IVerticalBarProps from './interfaces/verticalBar'
import { defaultLowerNavOptions, defaultUpperNavOptions } from './consts/navigation'
import NavOption from './NavOption'
import Avatar from './Avatar'

import styles from './styles/verticalBar.module.scss'

const VerticalBar: FunctionComponent<IVerticalBarProps> = ({
  activeNavOption = '',
  adjustForHorizontalBar = false,
  avatarDetail = null,
  bodyContent,
  fixed = false,
  height,
  lowerNavOptions = defaultLowerNavOptions,
  lowerSectionWrapperClass,
  upperNavOptions = defaultUpperNavOptions,
  upperSectionWrapperClass,
  wrapperClass,
  isEditStoreEnabled,
  onNavOptionClick,
  onAvatarClick,
  ...rest
}) => {
  const verticalClasses = clsx(
    styles.vertical,
    adjustForHorizontalBar && styles.adjustForHorizontalBar,
    fixed && styles.fixed,
    wrapperClass
  )

  if (typeof bodyContent !== 'undefined') {
    return (
      <header className={styles.vertical} data-testid="vertical-bar" {...rest}>
        {bodyContent}
      </header>
    )
  }

  const selectedNavOption =
    lowerNavOptions?.concat(upperNavOptions || [])?.find((navOption) => navOption.title === activeNavOption) || null

  const mapNavOption = (navOption: INavOption) => (
    <NavOption
      key={navOption.title}
      selected={selectedNavOption?.title === navOption.title}
      selectedNavOption={selectedNavOption}
      onClick={(evt: MouseEvent<HTMLAnchorElement>) => {
        if (navOption.softNav) evt.preventDefault()
        onNavOptionClick?.(navOption)
      }}
      onNavOptionClick={onNavOptionClick}
      id={navOption.id}
      {...navOption}
    />
  )
  const onAvatarClickHandler = isEditStoreEnabled && onAvatarClick ? onAvatarClick : undefined

  const upperSectionClasses = clsx(styles.upperSection, upperSectionWrapperClass)

  const renderLowerNavOptions = () => {
    const filteredLowerNavOptions = lowerNavOptions.filter((option) => !!option.destination || !!option.subNav)

    const lowerSectionClasses = clsx(
      styles.lowerSection,
      lowerSectionWrapperClass,
      filteredLowerNavOptions.length === 0 && styles.emptyLowerNavOptions
    )

    return (
      <div className={lowerSectionClasses}>
        {filteredLowerNavOptions
          .map(mapNavOption)
          .concat(<Avatar onClick={onAvatarClickHandler} key="avatar" {...avatarDetail} />)}
      </div>
    )
  }

  return (
    <div className={verticalClasses}>
      <nav className={styles.navContainer} data-testid="vertical-bar" style={{ height: `${height}` }} {...rest}>
        {upperNavOptions?.length > 0 && <div className={upperSectionClasses}>{upperNavOptions.map(mapNavOption)}</div>}
        {renderLowerNavOptions()}
      </nav>
    </div>
  )
}

export default VerticalBar
