import { useEffect, useRef } from 'react'

const DEFAULT_EVENTS = ['mousedown', 'touchstart']

const useClickOutside = <T extends HTMLElement = any>(
  handler: () => void,
  events?: string[] | null,
  nodes?: HTMLElement[]
) => {
  const ref = useRef<T>()

  useEffect(() => {
    const listener = (event: any) => {
      const { target } = event ?? {}
      if (!(target instanceof Node)) return

      if (Array.isArray(nodes)) {
        const shouldIgnore =
          (target instanceof Element && target.closest('[data-ignore-outside-clicks]') !== null) ||
          !document.body.contains(target)
        const shouldTrigger = nodes.every((node) => !!node && !node.contains(target))
        if (shouldTrigger && !shouldIgnore) {
          handler()
        }
      } else if (ref.current && !ref.current.contains(target)) {
        handler()
      }
    }

    const validEvents = events || DEFAULT_EVENTS
    validEvents.forEach((fn) => document.addEventListener(fn, listener))

    return () => {
      validEvents.forEach((fn) => document.removeEventListener(fn, listener))
    }
  }, [ref, handler, nodes])

  return ref
}

export default useClickOutside
