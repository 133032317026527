import { useEffect, useRef } from 'react'

type Props = {
  isOpen: boolean
  setIsShowing: Function
  closeBtnRef?: any
  disableScrollLock?: boolean
}

const onCloseAction = (disableScrollLock?: boolean) => {
  if (!disableScrollLock) {
    document.body.style.overflow = ''
    document.documentElement.style.overflow = ''
  }
}

const useOpenModal = ({ isOpen, setIsShowing, closeBtnRef, disableScrollLock = false }: Props) => {
  const firstUpdate = useRef(true)

  useEffect(() => {
    let isSubscribed = true
    if (isSubscribed) {
      if (firstUpdate.current) {
        firstUpdate.current = false
        return
      }

      if (isOpen) {
        setIsShowing(true)

        if (!disableScrollLock) {
          document.body.style.overflow = 'hidden'
          document.documentElement.style.overflow = 'hidden'
        }

        if (closeBtnRef.current !== null) {
          closeBtnRef.current.focus()
        }
      } else {
        setIsShowing(false)
        onCloseAction(disableScrollLock)
      }
    }
    return () => {
      onCloseAction(disableScrollLock)
      isSubscribed = false
    }
  }, [isOpen, disableScrollLock])
}

export default useOpenModal
