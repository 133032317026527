import { useContext } from 'react'
import styles from './productSearchModal.module.scss'
import ProductSearchForm from './ProductSearchForm'
import ProductSearchListRedesigned from './ProductSearchList/ProductSearchListRedesigned'
import IProductSearchModal from './interfaces/productSearchModal'
import Modal from '../Modal'
import { ProductSearchPopupCtx } from './context'
import { ProductSearchResultItem } from './schema'

const ProductSearchModalRedesigned = ({
  isOpen = true,
  title,
  subTitle,
  chipTitle,
  chipLabels,
  singleSelect,
  onProductSearchClose,
  analyticsPrefixId
}: IProductSearchModal) => {
  const { selectedItems } = useContext(ProductSearchPopupCtx)
  const handleClose = () => {
    if (onProductSearchClose) {
      onProductSearchClose(selectedItems)
    }
  }

  const handleAdd = (item?: ProductSearchResultItem) => {
    if (onProductSearchClose && singleSelect && item) {
      onProductSearchClose([item])
    }
  }

  return (
    <Modal
      isRedesign
      isOpen={isOpen}
      zIndex={1002}
      onClose={handleClose}
      className={styles.redesignModalWrapper}
      header={title}
      subheader={subTitle}
      borderStyle="redesign-round"
      disableScrollLock
      id={analyticsPrefixId}
    >
      <ProductSearchForm
        variant="withoutHeaderAndSubmitButton"
        isModalOpen={isOpen}
        chipTitle={chipTitle}
        chipLabels={chipLabels}
        analyticsPrefixId={analyticsPrefixId}
      />
      <ProductSearchListRedesigned onAdd={singleSelect ? handleAdd : undefined} analyticsPrefixId={analyticsPrefixId} />
    </Modal>
  )
}

export default ProductSearchModalRedesigned
