// DreamHub Storage Keys
const MFRM_UNIFY_CART_DETAIL_KEY = 'mfrm-unify-cart-detail'
const MFRM_UNIFY_SE_DETAIL_KEY = 'mfrm-unify-se-detail'
const MFRM_UNIFY_SESSION_INFO_KEY = 'mfrm-unify-session-info'
const MFRM_UNIFY_CUSTOMER_DETAIL_KEY = 'mfrm-unify-customer-detail'
const MFRM_UNIFY_MODAL_EDITOR_STORE_KEY = 'mfrm-unify-modal-editor-store'
const MFRM_UNIFY_NOTE_EDITED_DETAIL_KEY = 'mfrm-unify-note-edited-detail'
const MFRM_UNIFY_ACTIVE_CUSTOMER_DETAIL_KEY = 'mfrm-unify-active-customer-detail'
const MFRM_UNIFY_CART_CUSTOMER_KEY = 'mfrm-unify-cart-customer-info'
const MFRM_UNIFY_CART_ADDRESS_KEY = 'mfrm-unify-cart-address-info'
const MFRM_REST_LEAD_DETAILS_KEY = 'mfrm-rest-lead-details'
const MFRM_REST_LEAD_SAVED_KEY = 'mfrm-rest-lead-saved'
const MFRM_REST_NOTE_DETAILS_KEY = 'mfrm-rest-note-details'
const MFRM_UNIFY_REST_TAB_NAME = 'mfrm-unify-rest-tab-name'
const MFRM_UNIFY_CARE_TAB_NAME = 'mfrm-unify-care-tab-name'
const MFRM_UNIFY_CLIENTELING_TAB_NAME = 'mfrm-unify-clienteling-tab-name'
const MFRM_UNIFY_CLIENTELING_PRODUCT_SEARCH_ADDED = 'mfrm-unify-clienteling-product-search-selected'
const MFRM_UNIFY_SE_SIGNATURES_KEY = 'mfrm-unify-se-signatures'
const MFRM_REST_ORIGINAL_LEAD_DETAILS_KEY = 'mfrm-rest-original-lead-details'
const MFRM_REST_FILTER_STATUSES_KEY = 'mfrm-rest-filter-statuses'
const MFRM_CARE_FILTER_KEY = 'mfrm-care-filters'
const MFRM_UNIFY_VERTICAL_BAR_EXPANDED_KEY = 'mfrm-unify-vertical-bar-expanded-key'

// DreamHub Mattress Matcher Storage Keys
const MFRM_UNIFY_MM_ANSWERS_KEY = 'mattress-matcher-answers'
const MFRM_UNIFY_MM_D365_BASKET_UUID = 'mfrm-unify-msdyn365-cart'
const MFRM_UNIFY_MM_PENDING_ANSWERS_KEY = 'mfrm-unify-mm-pending-answers'
const MFRM_UNIFY_MM_POSITION_KEY = 'mfrm-unify-mm-position'
const MFRM_UNIFY_MM_PREVIEW_BANNER_KEY = 'mfrm-unify-preview-banner-key'
const MFRM_UNIFY_MM_QUIZ_STARTED = 'mfrm-unify-mm-quiz-started'
const MFRM_UNIFY_MM_RECOMMENDATIONS_KEY = 'mfrm-mm-unify-recommendations'
const MFRM_UNIFY_MM_RECENT_SEARCHES_KEY = 'mfrm-unify-mm-recent-searches'
const MFRM_UNIFY_MM_CART_DATA = 'mfrm-mm-cart-data'

// DreamHub Explore (X-Ray) Storage Keys
const MFRM_UNIFY_XRAY_PATH = 'mfrm-unify-xray-path'
const MFRM_UNIFY_XRAY_FAVORITES_KEY = 'mfrm-unify-xray-favorites'
const MFRM_UNIFY_XRAY_SELECTED_BRAND_KEY = 'mfrm-unify-xray-selected-brand'
const MFRM_UNIFY_XRAY_LAUNCH_EVENT = 'mfrm-unify-xray-launch-event'
const MFRM_UNIFY_XRAY_EVENT_CATEGORY = 'mfrm-unify-xray-event-category'

export {
  MFRM_UNIFY_ACTIVE_CUSTOMER_DETAIL_KEY,
  MFRM_UNIFY_CART_ADDRESS_KEY,
  MFRM_UNIFY_CART_CUSTOMER_KEY,
  MFRM_UNIFY_CART_DETAIL_KEY,
  MFRM_UNIFY_CUSTOMER_DETAIL_KEY,
  MFRM_UNIFY_MM_ANSWERS_KEY,
  MFRM_UNIFY_MM_D365_BASKET_UUID,
  MFRM_UNIFY_MM_PENDING_ANSWERS_KEY,
  MFRM_UNIFY_MM_POSITION_KEY,
  MFRM_UNIFY_MM_PREVIEW_BANNER_KEY,
  MFRM_UNIFY_MM_QUIZ_STARTED,
  MFRM_UNIFY_MM_RECENT_SEARCHES_KEY,
  MFRM_UNIFY_MM_RECOMMENDATIONS_KEY,
  MFRM_UNIFY_MODAL_EDITOR_STORE_KEY,
  MFRM_UNIFY_MM_CART_DATA,
  MFRM_UNIFY_NOTE_EDITED_DETAIL_KEY,
  MFRM_REST_LEAD_DETAILS_KEY,
  MFRM_REST_LEAD_SAVED_KEY,
  MFRM_REST_NOTE_DETAILS_KEY,
  MFRM_UNIFY_REST_TAB_NAME,
  MFRM_UNIFY_CARE_TAB_NAME,
  MFRM_UNIFY_CLIENTELING_TAB_NAME,
  MFRM_UNIFY_CLIENTELING_PRODUCT_SEARCH_ADDED,
  MFRM_UNIFY_SE_DETAIL_KEY,
  MFRM_UNIFY_SE_SIGNATURES_KEY,
  MFRM_UNIFY_SESSION_INFO_KEY,
  MFRM_UNIFY_XRAY_EVENT_CATEGORY,
  MFRM_UNIFY_XRAY_FAVORITES_KEY,
  MFRM_UNIFY_XRAY_LAUNCH_EVENT,
  MFRM_UNIFY_XRAY_PATH,
  MFRM_UNIFY_XRAY_SELECTED_BRAND_KEY,
  MFRM_REST_ORIGINAL_LEAD_DETAILS_KEY,
  MFRM_REST_FILTER_STATUSES_KEY,
  MFRM_CARE_FILTER_KEY,
  MFRM_UNIFY_VERTICAL_BAR_EXPANDED_KEY
}
