import { useContext, useMemo, useState } from 'react'
import clsx from 'clsx'
import { ProductSearchPopupCtx } from '../context'
import { ProductSearchResultItem } from '../schema'
import Typography from '../../Typography'
import ProductSearchListItemRedesigned from './ProductSearchListItemRedesigned'
import type IProductSearchList from './interfaces/productSearchList'

import styles from './productSearchList.module.scss'

const NO_SEARCH_RESULTS_MESSAGE = 'Not seeing a product? Please refine your search terms.'
const NO_HISTORY_RESULTS_MESSAGE = 'Not seeing a product? Please refine your category selection.'

const ProductSearchListRedesigned = ({ analyticsPrefixId, onAdd }: IProductSearchList) => {
  const [copiedProductSku, setCopiedProductSku] = useState<string | null>(null)
  const { isLoading, searchTerm, selectedChips, searchResults, filteredHistory, searchHistory } =
    useContext(ProductSearchPopupCtx)

  const displayedProducts = useMemo(() => {
    if (isLoading) return []
    const historyResults = selectedChips.length > 0 ? filteredHistory : searchHistory
    return searchTerm ? searchResults : historyResults
  }, [isLoading, searchTerm, searchResults, selectedChips, filteredHistory, searchHistory])

  const noResultsMessage = useMemo(() => {
    if (isLoading || displayedProducts.length > 0) return null
    if (searchTerm) {
      return NO_SEARCH_RESULTS_MESSAGE
    }
    return selectedChips.length > 0 ? NO_HISTORY_RESULTS_MESSAGE : null
  }, [isLoading, displayedProducts, searchTerm, selectedChips])

  return (
    <div className={clsx(styles.redesignModalRow, styles.content)}>
      <Typography variant="paragraph">{searchTerm ? 'Products' : 'History'}</Typography>
      <div className={styles.overflow}>
        {displayedProducts.length > 0
          ? displayedProducts.map((searchResultItem: ProductSearchResultItem, index: number) => (
              <ProductSearchListItemRedesigned
                key={searchResultItem.productSku}
                analyticsPrefixId={`${analyticsPrefixId}_result_pos${index}`}
                isCopied={copiedProductSku === searchResultItem.productSku}
                onCopiedChange={() => setCopiedProductSku(searchResultItem.productSku)}
                onAdd={onAdd}
                {...searchResultItem}
              />
            ))
          : noResultsMessage && (
              <Typography variant="paragraph" className={styles.noresult}>
                {noResultsMessage}
              </Typography>
            )}
      </div>
    </div>
  )
}

export default ProductSearchListRedesigned
