type Props = {
  className?: string
  width?: string | number
  height?: string | number
  fillColor?: string
}

const DreamHubLogo = ({ className, width = '32', height = '32', fillColor = '#1B244D' }: Props) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_5645_2259)">
      <mask
        id="mask0_5645_2259"
        style={{ maskType: 'luminance' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="32"
        height="32"
      >
        <path d="M32 0H0V32H32V0Z" fill="white" />
      </mask>
      <g mask="url(#mask0_5645_2259)">
        <path
          d="M25.7809 31.9776H6.23686C2.80219 31.9776 0.0181885 29.1936 0.0181885 25.7589V6.21494C0.0181885 2.78028 2.80219 -0.00372314 6.23686 -0.00372314H25.7809C29.2155 -0.00372314 31.9995 2.78028 31.9995 6.21494V25.7589C31.999 29.1931 29.215 31.9776 25.7809 31.9776Z"
          fill={fillColor}
        />
        <path
          d="M7.38028 26.8299C7.22188 26.6715 7.14294 26.4869 7.14294 26.2757V5.72961C7.14294 5.49761 7.21654 5.30241 7.36481 5.14401C7.51201 4.98561 7.70188 4.90668 7.93441 4.90668H16.2603C19.3835 4.90668 21.8107 5.65068 23.5413 7.13868C25.2715 8.62668 26.1792 10.7637 26.2635 13.5493C26.2843 14.1616 26.2949 14.9739 26.2949 15.9872C26.2949 17.0005 26.2837 17.8027 26.2635 18.3931C26.0523 24.176 22.7701 27.0672 16.4181 27.0672H7.93441C7.72321 27.0672 7.53868 26.9877 7.38028 26.8299Z"
          fill="white"
        />
        <path
          d="M16.5697 16.0645C15.5953 13.8725 16.1457 11.3893 17.7622 9.80215C16.5958 9.60961 15.3622 9.74241 14.2011 10.2587C11.0379 11.6645 9.61287 15.368 11.0187 18.5312C12.4246 21.6944 16.1281 23.1195 19.2913 21.7136C20.4523 21.1979 21.3782 20.3712 22.0166 19.3765C19.7553 19.5125 17.5441 18.2571 16.5697 16.0645Z"
          fill={fillColor}
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_5645_2259">
        <rect width="32" height="32" rx="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default DreamHubLogo