import clsx from 'clsx'
import { FunctionComponent, MouseEvent } from 'react'
import INavOption from './interfaces/navOption'
import IVerticalBarProps from './interfaces/verticalBar'
import { defaultLowerNavOptions, defaultUpperNavOptions } from './consts/navigation'
import NavOption from './NavOption'
import { DEFAULT_MERGE_GUIDE_URL, DEFAULT_USER_GUIDE_URL } from '../HorizontalBar/consts/menu'
import Typography from '../Typography'
import DreamHubLogo from '../Icons/DreamHubLogo'
import ChevronLeft from '../Icons/ChevronLeft'
import ChevronRight from '../Icons/ChevronRight'
import AvatarRedesign from './AvatarRedesign'
import Financing from './Financing'
import useClickOutside from '../MultiSelect/hooks/useClickOutside'

import styles from './styles/verticalBar.module.scss'

const VerticalBarRedesign: FunctionComponent<IVerticalBarProps> = ({
  activeNavOption = '',
  adjustForHorizontalBar = false,
  avatarDetail = null,
  bodyContent,
  fixed = false,
  height,
  lowerNavOptions = defaultLowerNavOptions,
  lowerSectionWrapperClass,
  upperNavOptions = defaultUpperNavOptions,
  upperSectionWrapperClass,
  wrapperClass,
  isEditStoreEnabled,
  onNavOptionClick,
  onAvatarClick,
  userGuideLink = DEFAULT_USER_GUIDE_URL,
  mergeGuideLink = DEFAULT_MERGE_GUIDE_URL,
  calculatorControls,
  isExpanded,
  setIsExpanded,
  ...rest
}) => {
  const ref = useClickOutside(() => setIsExpanded?.(false))

  const verticalClasses = clsx(
    styles.vertical,
    styles.redesign,
    adjustForHorizontalBar && styles.adjustForHorizontalBar,
    fixed && styles.fixed,
    isExpanded && styles.expanded,
    wrapperClass
  )

  if (typeof bodyContent !== 'undefined') {
    return (
      <header className={styles.vertical} data-testid="vertical-bar" {...rest}>
        {bodyContent}
      </header>
    )
  }

  const selectedNavOption =
    lowerNavOptions?.concat(upperNavOptions || [])?.find((navOption) => navOption.title === activeNavOption) || null

  const mapNavOption = (navOption: INavOption) => (
    <NavOption
      key={navOption.title}
      selected={selectedNavOption?.title === navOption.title}
      selectedNavOption={selectedNavOption}
      onClick={(evt: MouseEvent<HTMLAnchorElement>) => {
        if (navOption.softNav) evt.preventDefault()
        onNavOptionClick?.(navOption)
        setIsExpanded?.(false)
      }}
      onNavOptionClick={onNavOptionClick}
      id={navOption.id}
      isExpanded={isExpanded}
      isRedesign
      {...navOption}
    />
  )

  const upperSectionClasses = clsx(styles.upperSection, styles.redesign, upperSectionWrapperClass)
  const lowerSectionClasses = clsx(styles.lowerSection, styles.redesign, lowerSectionWrapperClass)

  const onAvatarClickHandler = isEditStoreEnabled && onAvatarClick ? onAvatarClick : undefined

  const onLinkClickHandler = (link: string) => {
    window.open(link, '_blank')?.focus()
  }

  return (
    <div ref={ref} className={verticalClasses}>
      <div className={styles.logoSection}>
        <DreamHubLogo />
        {isExpanded && <Typography variant="h1">DreamHUB</Typography>}
        <button className={styles.toggleBtn} onClick={() => setIsExpanded?.(!isExpanded)}>
          {isExpanded ? <ChevronLeft width={6} height={8} /> : <ChevronRight width={6} height={8} />}
        </button>
      </div>

      <nav className={styles.navContainer} data-testid="vertical-bar" style={{ height: `${height}` }} {...rest}>
        {upperNavOptions?.length > 0 && <div className={upperSectionClasses}>{upperNavOptions.map(mapNavOption)}</div>}
        {lowerNavOptions?.length > 0 && (
          <div className={lowerSectionClasses}>
            <div className={styles.lowerSectionTop}>
              {lowerNavOptions.filter((option) => !!option.destination || !!option.subNav).map(mapNavOption)}
              <Financing isExpanded={isExpanded} calculatorControls={calculatorControls} />
            </div>

            <AvatarRedesign
              onUserGuideClick={() => onLinkClickHandler(userGuideLink)}
              onMergeGuideClick={() => onLinkClickHandler(mergeGuideLink)}
              onEditStoreClick={onAvatarClickHandler}
              isExpanded={isExpanded}
              {...avatarDetail}
            />
          </div>
        )}
      </nav>
    </div>
  )
}

export default VerticalBarRedesign
