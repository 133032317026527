import { useContext } from 'react'
import styles from './productSearchList.module.scss'
import Button from '../../Button'
import { ProductSearchPopupCtx } from '../context'
import type IProductSearchListItem from './interfaces/productSearchListItem'

const ProductSearchListItem = ({
  productName,
  productSku,
  productIndex,
  analyticsPrefixId
}: IProductSearchListItem) => {
  const { handleClick } = useContext(ProductSearchPopupCtx)

  const { productSearchSku, productSearchListItem, productSearchProductName } = styles

  return (
    <Button
      analyticsID={analyticsPrefixId}
      btnType="link"
      data-testid={productSku}
      className={productSearchListItem}
      onClick={() => handleClick(productSku, productName, productIndex)}
    >
      <span className={productSearchProductName}>{productName}</span>
      <span className={productSearchSku}>({productSku})</span>
    </Button>
  )
}

export default ProductSearchListItem
