import { ChangeEvent, FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react'
import { useAtom } from 'jotai'
import { productSearchAtom } from '../../atoms/productSearchAtom'
import { ProductSearchPopupCtx } from '.'
import { ProductSearchResultItem, ProductSearchResults } from '../schema'
import { IProductSearchProviderProps } from '../interfaces/productSearchModal'
import { DEFAULT_RECENT_PRODUCT_SEARCHES } from '../../RecentProductSearches/context'
import { MFRM_UNIFY_MM_RECENT_SEARCHES_KEY } from '../../conventions/consts/storageKeys'
import { IStorageType } from '../../conventions'
import { useStorageReducer } from '../../useStorage'
import { recentProductSearchesReducer } from '../../RecentProductSearches'
import { RECENT_PRODUCT_SEARCHES_ACTION_TYPES } from '../../RecentProductSearches/interfaces'
import { getAbortController, getDebouncingFunction } from '../utils'
import { ProductType } from '../../consts'

export const ProductSearchProvider: FunctionComponent<IProductSearchProviderProps> = ({
  children,
  isModalOpen = false,
  enableFakeData = false,
  fakeSearchData = [],
  onListItemClick,
  onSuccessFullSearch,
  getProductSearchResult,
  selectedProducts
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, dispatch] = useStorageReducer(recentProductSearchesReducer, {
    value: DEFAULT_RECENT_PRODUCT_SEARCHES,
    key: MFRM_UNIFY_MM_RECENT_SEARCHES_KEY,
    storageType: IStorageType.Session
  })
  const [searchTerm, setSearchTerm] = useState('')
  const [selectedChips, setSelectedChips] = useState<ProductType[]>([])
  const [selectedItems, setSelectedItems] = useState<ProductSearchResultItem[]>(selectedProducts ?? [])
  const [isLoading, setIsLoading] = useState(false)
  const [searchResults, setSearchResults] = useState<ProductSearchResults>([])
  const [filteredHistory, setFilteredHistory] = useState<ProductSearchResults>([])
  const [searchHistory, setSearchHistory] = useAtom(productSearchAtom)

  useEffect(() => {
    if (!isModalOpen) {
      setSearchTerm('')
    }
  }, [isModalOpen])

  const productSearchAc = useMemo(() => getAbortController(), [])

  const handleSubmit = useCallback(
    async (value: string, filterCategories: ProductType[]) => {
      const lowerCaseString = value.trim().toLocaleLowerCase()
      if (lowerCaseString?.length > 0) {
        const ac = productSearchAc()
        setIsLoading(true)
        try {
          if (enableFakeData) {
            setSearchResults(fakeSearchData?.filter(({ productName }) => productName?.includes(lowerCaseString)))
          } else {
            const data = await getProductSearchResult(
              { searchTerm: lowerCaseString, filterCategories },
              { signal: ac.signal }
            )
            setSearchResults(data ?? [])
            setSearchHistory((prev) =>
              [...new Map([...prev, ...data].map((item) => [item.productSku, item])).values()].sort((a, b) =>
                a.productSku.localeCompare(b.productSku, undefined, { numeric: true })
              )
            )
            onSuccessFullSearch?.(data)
          }
        } catch (error) {
          console.error(error)
        }
      }
      setIsLoading(false)
    },
    [enableFakeData, fakeSearchData, getProductSearchResult, productSearchAc, onSuccessFullSearch]
  )

  const debouncedHandleSubmit = useMemo(() => getDebouncingFunction(handleSubmit), [handleSubmit])

  const handleSubmitBtnClick = useCallback(() => {
    handleSubmit(searchTerm, selectedChips)
  }, [handleSubmit, searchTerm, selectedChips])

  const handleReset = useCallback(() => {
    setSearchTerm('')
    setSearchResults([])
  }, [])

  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => (e.code === 'Enter' ? handleSubmit(searchTerm, selectedChips) : false),
    [handleSubmit, searchTerm, selectedChips]
  )

  const handleChipClick = useCallback(
    (label: string) => {
      setSelectedChips((prevSelected) => {
        const updatedSelected = prevSelected.includes(label as ProductType)
          ? prevSelected.filter((chip) => chip !== label)
          : [...prevSelected, label as ProductType]

        if (searchTerm) {
          handleSubmit(searchTerm, updatedSelected)
        } else {
          setFilteredHistory(() =>
            [...searchHistory]
              .filter(({ productType }) => productType && updatedSelected.includes(productType))
              .sort((a, b) => a.productSku.localeCompare(b.productSku, undefined, { numeric: true }))
          )
        }
        return updatedSelected
      })
    },
    [searchTerm, searchHistory, handleSubmit]
  )

  const handleSearchTerm = useCallback(
    ({ target: { value }, type }: ChangeEvent<HTMLInputElement>) => {
      if (type === 'blur') {
        return
      }

      if (value.trim().length > 0) {
        setSearchResults([])
        setIsLoading(true)
      }

      debouncedHandleSubmit(value, selectedChips)
      setSearchTerm(value)
    },
    [debouncedHandleSubmit, selectedChips]
  )

  const handleClick = useCallback(
    (productSku = '', productName = '', productIndex = 0) => {
      // Add to recent searches.
      dispatch({ type: RECENT_PRODUCT_SEARCHES_ACTION_TYPES.ADD, payload: { productName, productSku } })
      // Return the selected product sku to callback.
      onListItemClick?.(productSku, searchTerm, productIndex)
    },
    [dispatch, onListItemClick, searchTerm]
  )

  const handleSelectedItem = useCallback(
    (searchResultItem: ProductSearchResultItem) => {
      dispatch({
        type: RECENT_PRODUCT_SEARCHES_ACTION_TYPES.ADD,
        payload: {
          productName: searchResultItem.productName,
          productSku: searchResultItem.productSku,
          productId: searchResultItem.productId,
          variantId: searchResultItem.variantId
        }
      })
      setSelectedItems((prev: ProductSearchResultItem[]) => {
        if (prev.length >= 5) return prev
        return [...prev, searchResultItem]
      })
    },
    [dispatch, setSelectedItems]
  )

  // Memoized value
  const value = useMemo(
    () => ({
      isLoading,
      searchTerm,
      searchResults,
      filteredHistory,
      searchHistory,
      handleClick,
      handleKeyDown,
      handleReset,
      handleSearchTerm,
      handleSubmit: handleSubmitBtnClick,
      onListItemClick,
      handleChipClick,
      selectedChips,
      handleSelectedItem,
      selectedItems
    }),
    [
      isLoading,
      searchTerm,
      searchResults,
      handleClick,
      handleKeyDown,
      handleReset,
      handleSearchTerm,
      handleSubmitBtnClick,
      onListItemClick,
      handleChipClick,
      selectedChips,
      handleSelectedItem,
      selectedItems
    ]
  )

  return <ProductSearchPopupCtx.Provider value={value}>{children}</ProductSearchPopupCtx.Provider>
}
