import React, { useContext } from 'react'
import clsx from 'clsx'
import { ProductSearchResultItem } from '../schema'
import { ProductSearchPopupCtx } from '../context'
import { ProductType } from '../../consts'
import Typography from '../../Typography'
import Button from '../../Button'
import ControlledCopyButton from '../../ControlledCopyButton'
import ProductTypeMattress from '../../Icons/ProductTypeMattress'
import ProductTypeBedding from '../../Icons/ProductTypeBedding'
import ProductTypeFoundation from '../../Icons/ProductTypeFoundation'
import ProductTypeFurniture from '../../Icons/ProductTypeFurniture'
import ProductTypePillow from '../../Icons/ProductTypePillow'
import PlusRedesign from '../../Icons/PlusRedesign'
import CheckMark from '../../Icons/CheckMark'
import type IProductSearchListItem from './interfaces/productSearchListItem'

import styles from './productSearchList.module.scss'

const ProductSearchListItemRedesigned = ({
  productName,
  productSku,
  productType,
  productId,
  variantId,
  analyticsPrefixId,
  isCopied,
  onCopiedChange,
  onAdd
}: Omit<IProductSearchListItem, 'productIndex'> & {
  isCopied?: boolean
  onCopiedChange?: () => void
}) => {
  const { searchTerm, selectedItems, handleSelectedItem } = useContext(ProductSearchPopupCtx)

  const onSelect = () => {
    handleSelectedItem({ productName, productSku, productType, productId, variantId })
    onAdd?.({ productName, productSku, productType, productId, variantId })
  }

  const getHighlightedName = (name: string, searchKeyword: string): React.ReactNode => {
    if (!searchKeyword) return name

    const parts = name.split(new RegExp(`(${searchKeyword})`, 'gi'))

    return parts.map((part, index) => {
      const isMatch = part.toLowerCase() === searchKeyword.toLowerCase()
      return isMatch ? (
        // eslint-disable-next-line react/no-array-index-key
        <span key={index} className={styles.higlighted}>
          {part}
        </span>
      ) : (
        part
      )
    })
  }

  const productIcon = (iconType: ProductType | undefined | null): React.ReactNode => {
    const iconMap: Record<ProductType, React.ReactNode> = {
      Mattresses: <ProductTypeMattress />,
      Bedding: <ProductTypeBedding />,
      'Foundations & Bases': <ProductTypeFoundation />,
      Furniture: <ProductTypeFurniture />,
      Pillows: <ProductTypePillow />
    }
    return iconType ? iconMap[iconType] : null
  }

  const isItemInSearchResults = (itemSku: string) => {
    return selectedItems.some((existingItem: ProductSearchResultItem) => existingItem.productSku === itemSku)
  }

  const isSelectedItemsLimitReached = selectedItems.length >= 5

  return (
    <div className={styles.listItem}>
      <div className={styles.product}>
        <div className={styles.searchIcon}>{productIcon(productType)}</div>
        <div>
          <div>{getHighlightedName(productName, searchTerm)}</div>
          <Typography variant="paragraph">({productSku})</Typography>
        </div>
      </div>
      <div className={styles.actionButtons}>
        {isItemInSearchResults(productSku) ? (
          <Button type="button" className={clsx(styles.btn, styles.buttonAdded)} disabled>
            <CheckMark />
            Added
          </Button>
        ) : (
          <Button
            type="button"
            className={clsx(styles.btn, styles.buttonAdd, { [styles.disabled]: isSelectedItemsLimitReached })}
            onClick={onSelect}
            analyticsID={`${analyticsPrefixId}_add_button`}
            disabled={isSelectedItemsLimitReached}
          >
            <PlusRedesign fill={isSelectedItemsLimitReached ? '#626366' : '#1B244D'} />
            Add
          </Button>
        )}
        <ControlledCopyButton
          textToCopy={{ productName, productSku, productType, productId, variantId }}
          isCopied={isCopied}
          onCopied={onCopiedChange}
          analyticsID={`${analyticsPrefixId}_copy_button`}
        />
      </div>
    </div>
  )
}

export default ProductSearchListItemRedesigned
