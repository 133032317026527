import React, { useEffect, useRef, useState } from 'react'
import clsx from 'clsx'
import styles from './contextMenu.module.scss'
import MenuIcon from '../Icons/MenuIcon'
import useClickOutside from '../MultiSelect/hooks/useClickOutside'
import Portal from '../Portal'

type Positions = 'bottom-left' | 'bottom-right' | 'bottom-center' | 'top-left' | 'top-right' | 'top-center'

type ButtonProps = { onClick: () => void; isOpen?: boolean }

type Props = {
  children: React.ReactNode
  title: string
  className?: string
  contentClassName?: string
  position?: Positions
  ButtonComponent?: (props: ButtonProps) => JSX.Element
  onClick?: () => void
  open?: boolean
  closeOnClick?: boolean
  isNested?: boolean
  hasNested?: boolean
  onClose?: () => void
}

const positions: Record<Positions, React.CSSProperties> = {
  'bottom-left': {
    right: 0
  },
  'bottom-right': { left: 0 },
  'bottom-center': { left: '50%', transform: 'translateX(-50%)' },
  'top-left': {
    right: '0',
    bottom: '100%'
  },
  'top-right': {
    left: '0',
    bottom: '100%'
  },
  'top-center': {
    left: '50%',
    transform: 'translateX(-50%)',
    bottom: '100%'
  }
}

const ContextMenu: React.FC<Props> = ({
  children,
  title,
  className,
  contentClassName,
  position = 'bottom-left',
  ButtonComponent,
  onClick,
  open = false,
  closeOnClick = false,
  isNested = false,
  hasNested = false,
  onClose
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const handleClose = () => {
    onClose?.()
    setIsOpen(false)
  }
  const ref = useClickOutside(handleClose, ...(hasNested ? [['mousedown', 'touchstart'], []] : []))
  const buttonRef = useRef<HTMLDivElement>(null)
  const portalRef = useRef(null)
  const [coordinates, setCoordinates] = useState({ top: 0, left: 0 })

  useEffect(() => {
    const handleResize = () => {
      if (buttonRef?.current && isOpen) {
        const rect = buttonRef.current.getBoundingClientRect()
        let leftShift = 0
        if (position.includes('-left')) {
          leftShift = rect.width
        } else if (position.includes('-center')) {
          leftShift = rect.width / 2
        }
        setCoordinates({
          top: rect.bottom + window.scrollY - (position.includes('top-') ? rect.height : 0),
          left: rect.left + leftShift
        })
      }
    }

    if (isOpen) {
      handleResize()
    }

    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [ref, position, isOpen])

  useEffect(() => {
    setIsOpen(open)
  }, [open, setIsOpen])

  return (
    <div className={clsx(styles.wrapper, className)} ref={buttonRef}>
      {ButtonComponent ? (
        <ButtonComponent
          isOpen={isOpen}
          onClick={() => {
            setIsOpen(!isOpen)
            onClick?.()
          }}
        />
      ) : (
        <button
          className={clsx(styles.button, { [styles.open]: isOpen })}
          type="button"
          onClick={() => {
            setIsOpen(!isOpen)
            onClick?.()
          }}
        >
          <MenuIcon />
        </button>
      )}

      {isOpen && (
        <Portal ref={portalRef}>
          <div
            className={styles.contentWrapper}
            ref={ref}
            style={coordinates}
            data-ignore-outside-clicks={isNested ? true : undefined}
          >
            <div
              data-ignore-outside-clicks
              className={clsx(styles.content, contentClassName)}
              style={positions[position]}
              onClick={() => closeOnClick && handleClose()}
            >
              <div className={styles.title}>{title}</div>
              <div className={styles.children}>{children}</div>
            </div>
          </div>
        </Portal>
      )}
    </div>
  )
}

export default ContextMenu
