import React, { FunctionComponent } from 'react'
import HorizontalBar from '../HorizontalBar'
import HorizontalBarRedesign from '../HorizontalBar/HorizontalBarRedesign'
import IHorizontalBarProps from '../HorizontalBar/interfaces/horizontalBar'
import VerticalBar from '../VerticalBar'
import VerticalBarRedesign from '../VerticalBar/VerticalBarRedesign'
import IVerticalBarProps from '../VerticalBar/interfaces/verticalBar'
import { IEditStoreProps } from '../EditStoreProvider/interfaces/editStore'
import { EditStoreProvider, EditStoreContext } from '../EditStoreProvider'

interface Props {
  /** Horizontal Bar props */
  horizontalBarProps?: IHorizontalBarProps
  /** Vertical Bar props */
  verticalBarProps?: IVerticalBarProps
  /** Edit My Store props */
  editStoreProps?: IEditStoreProps
  /** Unables redesigned Vertical Bar */
  isRedesign?: boolean
  [rest: string]: unknown // ...rest property
}

const Navigation: FunctionComponent<Props> = ({
  horizontalBarProps,
  verticalBarProps,
  editStoreProps,
  isRedesign,
  ...rest
}) => {
  const isEditStoreEnabled = !!editStoreProps
  return (
    <EditStoreProvider {...editStoreProps}>
      <EditStoreContext.Consumer>
        {({ toggleEditStoreModal, setIdPrefixEditStoreModal }) =>
          isRedesign ? (
            <>
              <HorizontalBarRedesign {...horizontalBarProps} {...rest} />
              <VerticalBarRedesign
                isEditStoreEnabled={isEditStoreEnabled}
                onAvatarClick={toggleEditStoreModal}
                activeNavOption={verticalBarProps?.activeNavOption || 'Home'}
                {...verticalBarProps}
                {...rest}
              />
            </>
          ) : (
            <>
              <HorizontalBar
                isEditStoreEnabled={isEditStoreEnabled}
                onEditStoreClick={toggleEditStoreModal}
                setIdPrefixEditStoreModal={setIdPrefixEditStoreModal}
                handleSearchSubmit={() => {}}
                {...horizontalBarProps}
                {...rest}
              />
              <VerticalBar
                isEditStoreEnabled={isEditStoreEnabled}
                onAvatarClick={toggleEditStoreModal}
                activeNavOption={verticalBarProps?.activeNavOption || 'Home'}
                {...verticalBarProps}
                {...rest}
              />
            </>
          )
        }
      </EditStoreContext.Consumer>
    </EditStoreProvider>
  )
}

export default Navigation
