import { useState } from 'react'
import clsx from 'clsx'
import Typography from '../Typography'
import FinancingIcon from '../Icons/FinancingIcon'
import ChevronRight from '../Icons/ChevronRight'
import ExternalLinkIcon from '../Icons/ExternalLinkIcon'
import ProgressiveLogo from '../Icons/ProgressiveLogo'
import SynchronyLogo from '../Icons/SynchronyLogo'
import useClickOutside from '../MultiSelect/hooks/useClickOutside'
import { CalculatorControls } from '../HorizontalBar/interfaces/hamburgerMenu'
import calculatorsLinks from '../Calculators/const'

import styles from './styles/financing.module.scss'

type Props = {
  idPrefix?: string
  isExpanded?: boolean
  calculatorControls?: CalculatorControls
}

const Financing = ({ idPrefix, isExpanded, calculatorControls }: Props) => {
  const [isSubmenuOpen, setIsSubmenuOpen] = useState(false)
  const ref = useClickOutside(() => setIsSubmenuOpen(false))

  const renderLink = (title: string, link: string, id?: string) => (
    <div>
      <a href={link} target="_blank" rel="noopener noreferrer" className={styles.link} id={id}>
        <div>
          <Typography variant="h5">{title}</Typography>
          <span>Button</span>
        </div>
        <ExternalLinkIcon fillColor="#2D2926" />
      </a>
    </div>
  )

  return (
    <div ref={ref} className={clsx(styles.financing, { [styles.opened]: isSubmenuOpen })}>
      <div className={styles.content} onClick={() => setIsSubmenuOpen(!isSubmenuOpen)}>
        <div className={styles.title}>
          <FinancingIcon />
          {isExpanded && <span>Financing</span>}
        </div>
        {isExpanded && <ChevronRight height={15} width={9} />}
      </div>

      {isSubmenuOpen && (
        <div className={styles.submenu}>
          <Typography variant="h6">FINANCING</Typography>
          <div className={styles.links}>
            <div>
              <SynchronyLogo width={111} height={24} />
            </div>

            {renderLink(
              'Calculator',
              calculatorControls && calculatorControls.displaySynchronyCalculatorBrandedStore
                ? calculatorsLinks.synchronyBrandedStore
                : calculatorsLinks.defaultSynchrony,
              `${idPrefix}_financing_submenu_synchrony_calculator_link`
            )}

            {renderLink(
              'Financing Options',
              calculatorControls && calculatorControls.displaySynchronyCalculatorBrandedStore
                ? calculatorsLinks.finantialOptionBrandedStore
                : calculatorsLinks.defaultFinantailOption,
              `${idPrefix}_financing_submenu_synchrony_financing_options_link`
            )}

            <div className={styles.divider}></div>

            <div>
              <ProgressiveLogo width={101} height={24} />
            </div>

            {renderLink(
              'Calculator',
              calculatorsLinks.progressive,
              `${idPrefix}_financing_submenu_progressive_leasing_calculator_link`
            )}
          </div>
        </div>
      )}
    </div>
  )
}

export default Financing
