import React, { useEffect } from 'react'
import styles from './productSearchModal.module.scss'
import ProductSearchList from './ProductSearchList'
import ProductSearchForm from './ProductSearchForm'
import { ProductSearchProvider } from './context/provider'
import IProductSearchModal from './interfaces/productSearchModal'
import Modal from '../Modal'
import { fakeProductSearchResults } from './fakeProductSearchData'
import ProductSearchModalRedesigned from './ProductSearchModalRedesigned'

const ProductSearchModal = ({
  enableFakeData = false,
  fakeSearchData = [],
  isOpen = true,
  title,
  subTitle,
  chipTitle,
  chipLabels,
  singleSelect = false,
  getProductSearchResult,
  onSuccessFullSearch,
  onClose = () => null,
  onProductSearchClose,
  onListItemClick,
  analyticsPrefixId,
  variant = 'current',
  ...rest
}: IProductSearchModal) => {
  useEffect(() => {
    document.body.style.overflowY = 'hidden'
    return () => {
      document.body.style.overflowY = 'auto'
    }
  }, [])

  if (variant === 'current') {
    return (
      <Modal
        isOpen={isOpen}
        zIndex={1002}
        closeButtonClass={styles.close}
        childrenClass={styles.noContentBorder}
        onClose={onClose}
        className={styles.wrapperProductSearchModal}
      >
        <ProductSearchProvider
          enableFakeData={enableFakeData}
          fakeSearchData={fakeSearchData}
          getProductSearchResult={getProductSearchResult}
          onListItemClick={onListItemClick}
          onSuccessFullSearch={onSuccessFullSearch}
          isModalOpen={isOpen}
          {...rest}
        >
          <div className={styles.productSearchContentContainer}>
            <ProductSearchForm
              isModalOpen={isOpen}
              title={title}
              subTitle={subTitle}
              analyticsPrefixId={analyticsPrefixId}
            />
          </div>
          <ProductSearchList analyticsPrefixId={analyticsPrefixId} />
        </ProductSearchProvider>
      </Modal>
    )
  }
  return (
    <ProductSearchProvider
      enableFakeData={enableFakeData}
      fakeSearchData={fakeSearchData}
      getProductSearchResult={getProductSearchResult}
      onListItemClick={onListItemClick}
      onSuccessFullSearch={onSuccessFullSearch}
      isModalOpen={isOpen}
      {...rest}
    >
      <ProductSearchModalRedesigned
        isOpen={isOpen}
        enableFakeData={enableFakeData}
        fakeSearchData={fakeSearchData}
        isModalOpen={isOpen}
        title={title}
        subTitle={subTitle}
        chipTitle={chipTitle}
        chipLabels={chipLabels}
        getProductSearchResult={getProductSearchResult}
        onListItemClick={onListItemClick}
        onSuccessFullSearch={onSuccessFullSearch}
        onProductSearchClose={onProductSearchClose}
        variant="redesigned"
        singleSelect={singleSelect}
        analyticsPrefixId={analyticsPrefixId}
      />
    </ProductSearchProvider>
  )
}

export { fakeProductSearchResults }
export default ProductSearchModal
