import { FunctionComponent, useState } from 'react'
import clsx from 'clsx'
import Typography from '../Typography'
import ChevronRight from '../Icons/ChevronRight'
import IAvatarDetail, { IAvatarProps, IUnifySleepExpertDetail } from './interfaces/avatar'
import { MFRM_UNIFY_SE_DETAIL_KEY } from '../conventions'
import useClickOutside from '../MultiSelect/hooks/useClickOutside'
import useAvatarData from './hooks/useAvatarData'

import styles from './styles/avatarRedesign.module.scss'

interface Props extends IAvatarDetail, IUnifySleepExpertDetail, IAvatarProps {
  isExpanded?: boolean
  onUserGuideClick?: () => void
  onMergeGuideClick?: () => void
  onEditStoreClick?: () => void
  idPrefix?: string
}

const AvatarRedesign: FunctionComponent<Props> = ({
  name = '',
  title = '',
  storeNumber = '',
  showLastName = false,
  storageKey = MFRM_UNIFY_SE_DETAIL_KEY,
  isExpanded = false,
  onUserGuideClick,
  onMergeGuideClick,
  onEditStoreClick,
  idPrefix = ''
}) => {
  const [isSubmenuOpen, setIsSubmenuOpen] = useState(false)
  const ref = useClickOutside(() => setIsSubmenuOpen(false))

  const { displayName, avatarTitle, initials } = useAvatarData(name, title, storeNumber, showLastName, storageKey)

  return (
    <>
      <div
        className={clsx(styles.container, {
          [styles.expandedContainer]: isExpanded
        })}
        onClick={() => setIsSubmenuOpen(!isSubmenuOpen)}
      >
        <div className={clsx(styles.circle)}>{initials}</div>

        {isExpanded && (
          <div className={styles.details}>
            <div className={styles.data}>
              <span title={displayName} className={styles.name}>
                {displayName}
              </span>
              <span title={avatarTitle} className={styles.title}>
                {avatarTitle}
              </span>
            </div>
            <ChevronRight height={15} width={9} />
          </div>
        )}
      </div>

      {isSubmenuOpen && (
        <div className={styles.submenu} ref={ref}>
          <Typography variant="h6">SLEEP EXPERT</Typography>
          <div className={styles.buttons}>
            <button
              type="button"
              className={styles.button}
              onClick={onUserGuideClick}
              id={`${idPrefix}_avatar_user_guide_button`}
            >
              User Guide
            </button>

            <button
              type="button"
              onClick={onMergeGuideClick}
              className={styles.button}
              id={`${idPrefix}_avatar_merge_process_explained_button`}
            >
              Merge Process Explained
            </button>

            <button
              type="button"
              className={styles.button}
              onClick={() => {
                onEditStoreClick?.()
                setIsSubmenuOpen(false)
              }}
              id={`${idPrefix}_avatar_edit_store_button`}
            >
              Edit Store
            </button>
          </div>
        </div>
      )}
    </>
  )
}

export default AvatarRedesign
